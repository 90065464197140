<template>
  <div class="base-saas">
    <el-popover
      placement="bottom"
      trigger="hover"
      popper-class="base-saas-popover"
    >
      <div
        v-for="saas of saasList"
        :key="saas.id"
        class="saas"
        @click="handleSaas(saas)"
      >
        <img :src="saas.appIcon.substring(saas.appIcon.indexOf('_:/') + 3)" />
        <span>{{ saas.name }}</span>
      </div>
      <svg slot="reference">
        <use xlink:href="#icon-layout-vii"></use>
      </svg>
    </el-popover>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import { getSaas } from '@/api/admin/menu'
export default {
  data() {
    return {
      saasList: [],
    }
  },
  created() {
    getSaas(
      JSON.parse(Cookie.get('OO-refresh_userInfo')).content.username,
    ).then(res => {
      this.saasList = res.data.data
    })
  },
  methods: {
    handleSaas(app) {
      if (app.isPlatFormFlag === '1') {
        if (app.appPath) {
          const token = JSON.parse(Cookie.get('OO-access_token')).content
          app.appPath = app.appPath.replace(
            /\$\{domain\}/,
            window.location.host,
          )
          app.appPath = app.appPath.replace(/\$\{token\}/, token)
          if (app.appPath.indexOf('http') !== -1) {
            window.open(app.appPath, '_blank')
          } else {
            window.open(`${window.location.protocol}//${app.appPath}`, '_blank')
          }
        } else {
          const origin = window.location.host.split('.')
          origin.splice(0, 1)
          const topOrigin = origin.join('.')
          window.open(
            `${window.location.protocol}//${app.appCode}.${topOrigin}`,
            '_blank',
          )
        }
      } else {
        window.open(`${window.location.origin}/app/${app.appCode}/`, '_blank')
      }
    },
  },
}
</script>

<style lang="scss">
.base-saas {
  margin: 0 16px 0 64px;
  font-size: 20px;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
  }
}
.base-saas-popover {
  padding: 24px !important;
  padding-bottom: 0 !important;
  max-width: 500px;
  .saas {
    min-width: 200px;
    width: 50%;
    display: inline-block;
    margin: 0 0 24px 0;
    line-height: 25px;
    cursor: pointer;
  }
  img {
    height: 25px;
    margin-right: 8px;
    vertical-align: bottom;
  }
}
</style>
