<template>
  <div id="app">
    <div v-show="authLoading" class="auth-loading">资源加载中，请稍后. . .</div>
    <router-view />
  </div>
</template>

<script>
import { randomLenNum } from '@/util/util'
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  data() {
    return {
      authLoading: false,
      loginForm: {
        username: '',
        password: '*onlyoneauth*',
        code: '',
        redomStr: '',
        r_url: '',
      },
      code: {
        src: '/code',
        value: '',
        len: 4,
        type: 'image',
      },
    }
  },
  computed: {
    ...mapGetters(['tagWel']),
  },
  watch: {},
  created() {
    this.urlAccess()
  },
  methods: {
    refreshCode() {
      this.loginForm.code = ''
      this.loginForm.randomStr = randomLenNum(this.code.len, true)
      this.code.type === 'text'
        ? (this.code.value = randomLenNum(this.code.len))
        : (this.code.src = `${this.codeUrl}?randomStr=${this.loginForm.randomStr}`)
    },
    handleLogin() {
      this.authLoading = true
      if (window.location.href.indexOf('?userName') !== -1) {
        const fullname = window.location.href.split('?')[1]
        let name = fullname.split('=')[1]
        const index = name.lastIndexOf('#')
        name = name.slice(0, index)
        this.loginForm.username = name
        this.$store
          .dispatch('LoginByUsername', this.loginForm)
          .then(() => {
            this.$router.push({
              path: this.tagWel.value,
            })
          })
          .catch(() => {
            this.refreshCode()
            this.authLoading = false
          })
      }
      this.authLoading = false
    },
    urlAccess() {
      this.refreshCode()
      this.$nextTick(() => {
        this.handleLogin()
      })
    },
  },
}
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'PingFang SC', 'Microsoft YaHei', '微软雅黑', 'Hiragino Sans GB',
    Arial, sans-serif, 'Helvetica Neue', Helvetica !important;
}
::-webkit-scrollbar {
  width: 0;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #c7d0d9;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}
</style>
