import 'babel-polyfill'
import 'classlist-polyfill'
import Vue from 'vue'
import axios from './router/axios'
import VueAxios from 'vue-axios'
import App from './App'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Avue from '@smallwei/avue'
import '@smallwei/avue/lib/index.css'
import avueFormDesign from 'avue-plugin-formdesign'
import './permission' // 权限
import './error' // 日志
import router from './router/router'
import store from './store'
import { getUrlQuery, getUrlHostname } from './util/util'
import * as urls from '../public/js/env'
// import {
//   iconfontUrl,
//   iconfontVersion
// } from '../public/js/env'
import './styles/iconfonts/iconfont.js'
import * as filters from './filters' // 全局filter
import './styles/common.scss'
import basicContainer from './components/basic-container/main'
import vueParticles from 'vue-particles'
import { removeStore } from '@/util/store'
import Cookie from 'js-cookie'
import { getByAppCode } from '@/api/login'
import { setStore } from '@/util/store'

// element全局配置
import './styles/theme/uploadConfig.js'
// 从平台级别的系统跳转到paas的登录时，需要清空cookie
const r_url = getUrlQuery().r_url
if (r_url) {
  const r_hostname = getUrlHostname(r_url)
  const hostname = location.hostname
  if (r_hostname !== hostname) {
    const accessToken = Cookie.get('OO-access_token')
    const token = accessToken ? JSON.parse(accessToken).content : ''
    if (token) {
      removeStore({ name: 'expires_in' })
      removeStore({ name: 'refresh_userInfo' })
      removeStore({ name: 'refresh_token' })
      removeStore({ name: 'access_token' })
      location.reload()
    }
  }
}
getByAppCode()
  .then(res => {
    const { appFullPicture, appIcon, id, displayName } = res.data.data
    const content = {
      appFullPicture: appFullPicture
        ? appFullPicture.substring(appFullPicture.indexOf('_:/') + 3)
        : '',
      appIcon: appIcon ? appIcon.substring(appIcon.indexOf('_:/') + 3) : '',
      displayName: displayName || '',
    }
    setStore({ name: 'paas-icon', content: content, type: '' })
    setStore({ name: 'appId', content: { appId: id }, type: '' })
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = content.appIcon
    document.getElementsByTagName('head')[0].appendChild(link)
  })
  .catch(() => {})

Vue.use(VueAxios, axios)
Vue.use(vueParticles)

Vue.use(ElementUI, {
  size: 'small',
  menuType: 'text',
})

Vue.use(Avue, {
  size: 'medium',
  menuType: 'text',
})

Vue.use(router)

Vue.use(avueFormDesign)

// 注册全局容器
Vue.component('basicContainer', basicContainer)

// 加载相关url地址
Object.keys(urls).forEach(key => {
  Vue.prototype[key] = urls[key]
})

// 加载过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// 动态加载阿里云字体库
// iconfontVersion.forEach(ele => {
//   loadStyle(iconfontUrl.replace('$key', ele))
// })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
