/**
 * 全站权限配置
 *
 */
import router from './router/router'
import store from '@/store'
import { validatenull } from '@/util/validate'
import { getUrlQuery } from '@/util/util'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ showSpinner: false })

/**
 * 导航守卫，相关内容可以参考:
 * https://router.vuejs.org/zh/guide/advanced/navigation-guards.html
 */
router.beforeEach((to, from, next) => {
  // 缓冲设置
  if (
    to.meta.keepAlive === true &&
    store.state.tags.tagList.some(ele => {
      // if (store.state.tags.tagList.some(ele => {
      return ele.value === to.fullPath
    })
  ) {
    to.meta.$keepAlive = true
  } else {
    NProgress.start()
    if (to.meta.keepAlive === true && validatenull(to.meta.$keepAlive)) {
      to.meta.$keepAlive = true
    } else {
      to.meta.$keepAlive = false
    }
  }
  const meta = to.meta || {}
  if (store.getters.access_token) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      const value = to.query.src || to.fullPath
      const label = to.query.name || to.name
      const station = {
        label: label,
        value: value,
        params: to.params,
        query: to.query,
        group: router.$avueRouter.group || [],
      }
      // NOTE: 当用户角色不存在时，会存在无限请求用户信息接口的问题
      if (store.getters.roles.length === 0) {
        if (window.location.href.indexOf('r_url=') !== -1) {
          const url = decodeURIComponent(
            window.location.href.split('r_url=')[1],
          )
          next({ path: `/login?r_url=${url}` })
        } else {
          store
            .dispatch('GetUserInfo')
            .then(() => {
              next()
              store.commit('ADD_TAG', station)
            })
            .catch(() => {
              store.dispatch('FedLogOut').then(() => {
                next({ path: '/login' })
              })
            })
        }
      } else {
        if (to.fullPath.indexOf('/console/info/index?') !== -1) {
          const codeMessage = getUrlQuery() || {}
          localStorage.setItem('OO-codeMessage', JSON.stringify(codeMessage))
          station.value = '/console/info/index'
          next({ path: '/console/info/index' })
          NProgress.done()
        } else {
          if (
            meta.isTab !== false &&
            !validatenull(value) &&
            !validatenull(label)
          ) {
            store.commit('ADD_TAG', station)
          }
          next()
        }
      }
    }
  } else {
    if (meta.isAuth === false) {
      next()
    } else {
      store.dispatch('FedLogOut').then(() => {
        next({ path: '/login' })
      })
    }
  }
})

router.afterEach(() => {
  NProgress.done()
  // 去除动态加载title
  // const title = store.getters.tag.label
  // router.$avueRouter.setTitle(title)
})
