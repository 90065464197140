import Layout from '@/page/index/'
export default [
  {
    path: '/console/wel',
    component: Layout,
    redirect: '/console/wel/index',
    children: [
      {
        path: 'index',
        name: '应用中心',
        component: () => import(/* webpackChunkName: "views" */ '@/page/wel'),
      },
    ],
  },
  {
    path: '/link',
    component: Layout,
    redirect: '/link/type-link',
    children: [
      {
        path: 'type-link',
        name: '外链',
        component: () => import('@/page/index/type-link'),
      },
    ],
  },
  {
    path: '/console/admin/info',
    component: Layout,
    redirect: '/console/admin/info/index',
    children: [
      {
        path: 'index',
        name: '个人信息',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/admin/info/index'),
      },
    ],
  },
  {
    path: '/console/admin/audit',
    component: Layout,
    redirect: '/console/admin/audit/index',
    children: [
      {
        path: 'index',
        name: '操作审计',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/admin/audit/index'),
      },
    ],
  },
  {
    path: '/console/admin/login/',
    component: Layout,
    redirect: '/console/admin/login/index',
    children: [
      {
        path: 'index',
        name: '个人信息',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/admin/login/index'),
      },
    ],
  },
]
