<template>
  <div class="menu-wrapper">
    <div v-for="item in menu" :key="item[pathKey]">
      <el-menu-item
        v-if="
          validatenull(item[childrenKey]) &&
          vaildRoles(item) &&
          (item.hasPermission === 1 ||
            (item.hasPermission === 0 && item.noPermitView === 'disable'))
        "
        :key="item[labelKey]"
        :disabled="item.hasPermission === 0 && item.hasPermission === 'disable'"
        :index="item[pathKey]"
        :class="{ 'is-active': vaildActive(item) }"
        @click="open(item)"
      >
        <!-- <img :src="item[iconKey]" style="margin-right: 8px" alt="" /> -->
        <i :class="['iconfont', item[iconKey]]"></i>
        <span slot="title" :alt="item[pathKey]">{{ item[labelKey] }}</span>
      </el-menu-item>
      <el-submenu
        v-else-if="
          !validatenull(item[childrenKey]) &&
          vaildRoles(item) &&
          (item.hasPermission === 1 ||
            (item.hasPermission === 0 && item.noPermitView === 'disable'))
        "
        :key="item[labelKey]"
        :index="item[pathKey]"
        :class="{ 'is-active': vaildActive(item, true) }"
      >
        <template slot="title">
          <!-- <img :src="item[iconKey]" style="margin-right: 8px" alt="" /> -->
          <i :class="['iconfont', item[iconKey]]"></i>
          <span
            slot="title"
            :class="{ 'el-menu--display': collapse && first }"
            >{{ item[labelKey] }}</span
          >
        </template>
        <div
          v-for="(child, cindex) in item[childrenKey]"
          :key="child[labelKey]"
        >
          <el-menu-item
            v-if="
              validatenull(child[childrenKey]) &&
              (child.hasPermission === 1 ||
                (child.hasPermission === 0 && child.noPermitView === 'disable'))
            "
            :key="child[labelKey]"
            :index="`${child[pathKey]},${cindex}`"
            :class="{ 'is-active': vaildActive(child) }"
            :disabled="
              child.hasPermission === 0 && child.noPermitView === 'disable'
            "
            style="padding-left: 12px !important"
            @click="open(child)"
          >
            <!-- <img :src="child[iconKey]" style="margin-right: 8px" alt="" /> -->
            <i class="iconfont icon-dian children__icon"></i>
            <span slot="title">{{ child[labelKey] }}</span>
          </el-menu-item>
          <sidebar-item
            v-else
            :key="cindex"
            :menu="[child]"
            :props="props"
            :screen="screen"
            :collapse="collapse"
          ></sidebar-item>
        </div>
      </el-submenu>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { validatenull } from '@/util/validate'
import config from './config.js'
export default {
  name: 'SidebarItem',
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
    screen: {
      type: Number,
      default: 0,
    },
    first: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
      default: () => {
        return {}
      },
    },
    collapse: {
      type: Boolean,
    },
  },
  data() {
    return {
      config: config,
      defaultOpeneds: [],
    }
  },
  computed: {
    ...mapGetters(['roles', 'tag']),
    labelKey() {
      return this.props.label || this.config.propsDefault.label
    },
    pathKey() {
      return this.props.path || this.config.propsDefault.path
    },
    iconKey() {
      return this.props.icon || this.config.propsDefault.icon
    },
    childrenKey() {
      return this.props.children || this.config.propsDefault.children
    },
    nowTagValue() {
      return this.$router.$avueRouter.getValue(this.$route)
    },
  },
  watch: {
    defaultOpeneds: {
      deep: true,
      handler() {
        this.$emit('getDefaultOpeneds', this.defaultOpeneds)
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    vaildActive(item, group) {
      if (group) {
        const groupFlag = item.children.some(ele => {
          return ele.path === this.nowTagValue
        })
        if (groupFlag) {
          this.defaultOpeneds = [item.path]
        }

        return groupFlag
      } else {
        const groupFlag = (item['group'] || []).some(ele =>
          this.$route.path.includes(ele),
        )
        return this.nowTagValue === item[this.pathKey] || groupFlag
      }
    },
    vaildRoles(item) {
      item.meta = item.meta || {}
      return item.meta.roles ? item.meta.roles.includes(this.roles) : true
    },
    validatenull(val) {
      return validatenull(val)
    },
    open(item) {
      // 接口文档替换domain值
      if (item.label === '接口文档') {
        item.path = item.path.replace(/\$\{domain\}/, window.location.origin)
      }
      console.log(item.path)
      if (this.screen <= 1) this.$store.commit('SET_COLLAPSE')
      this.$router.$avueRouter.group = item.group
      if (item.path.indexOf('type=link') === -1) {
        this.$router.push({
          path: this.$router.$avueRouter.getPath({
            name: item[this.labelKey],
            src: item[this.pathKey],
          }),
          query: item.query,
        })
      } else {
        this.$router.push({
          path: '/link/type-link',
          query: {
            url: item.path,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$active-color: #165dff;
.menu-wrapper {
  ::v-deep .el-menu {
    height: 100%;
    background-color: #fff;
    border-right: none;
    color: #86909c;
    &:not(.el-menu--collapse) {
      width: 192px;
      overflow: hidden;
    }
    .el-menu-item,
    .el-submenu__title {
      background-color: #fff;
      color: #86909c;
      height: 48px;
      display: flex;
      align-items: center;
      i {
        color: #86909c;
        margin-right: 8px;
      }
      span {
        color: #86909c;
      }
      &.is-active {
        background: transparent !important;
        span {
          color: $active-color !important;
        }
        i {
          color: $active-color !important;
        }
        .children__icon {
          color: #165dff;
        }
      }
      &:hover {
        background: transparent;
        i {
          color: $active-color;
        }
        span {
          color: $active-color;
        }
      }
      .children__icon {
        font-size: 30px;
        margin: 0 4px 0 1px;
      }
    }
    .el-submenu {
      .el-icon-arrow-right {
        display: none;
      }
    }

    .is-opened {
      background: transparent;
    }

    .is-collapse {
      cursor: pointer;
      &:hover {
        background: transparent;
      }
    }
  }
}
</style>
