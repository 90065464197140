<template>
  <div class="avue-top">
    <div class="top-bar__left">
      <img :src="appIcon" class="collapse-icon" alt="" />
      <span slot="title" style="font-size: 18px">{{ displayName }}</span>
      <base-saas />
    </div>
    <div class="top-bar__right">
      <top-theme />
      <el-dropdown>
        <el-button circle type="primary" :plain="false">{{
          formatedUser
        }}</el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item class="drop-logout" divided @click.native="logout"
            >退出系统
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { fullscreenToggel, listenfullscreen } from '@/util/util'
import topTheme from './top-theme'
import baseSaas from './base-saas'
import Cookie from 'js-cookie'
export default {
  name: 'Top',
  components: {
    topTheme,
    baseSaas,
  },
  filters: {},
  data() {
    return {
      appIcon: '',
      displayName: '',
    }
  },
  computed: {
    formatedUser: function () {
      let userInfo = this.userInfo.displayName
        ? decodeURIComponent(this.userInfo.displayName)
        : this.userInfo.username
      return userInfo.slice(0, 1).toUpperCase()
    },
    ...mapState({
      showLock: state => state.common.showLock,
      showFullScren: state => state.common.showFullScren,
      showCollapse: state => state.common.showCollapse,
      showMenu: state => state.common.showMenu,
    }),
    ...mapGetters([
      'userInfo',
      'isFullScreen',
      'tagWel',
      'tagList',
      'isCollapse',
      'tag',
      'logsLen',
      'logsFlag',
      'keyCollapse',
      'menu',
    ]),
  },
  created() {
    const userId = JSON.parse(Cookie.get('OO-refresh_userInfo')).content.userId
    this.$store
      .dispatch('GetMenu', { type: true, userId })
      .then(data => {
        this.initLoading = false
        if (data.length === 0) return
        this.$router.$avueRouter.formatRoutes(data, true)
      })
      .catch(() => {
        this.initLoading = false
      })
    const appIcon = localStorage.getItem('OO-paas-icon')
      ? JSON.parse(localStorage.getItem('OO-paas-icon')).content.appIcon
      : ''
    const displayName = localStorage.getItem('OO-paas-icon')
      ? JSON.parse(localStorage.getItem('OO-paas-icon')).content.displayName
      : ''

    this.appIcon = appIcon || this.appIcon
    this.displayName = displayName || this.displayName
    const link = document.createElement('link')
    document.title = this.displayName
    link.rel = 'shortcut icon'
    link.href = this.appIcon
    $('head').append(link)
  },
  mounted() {
    listenfullscreen(this.setScreen)
  },
  methods: {
    handleScreen() {
      fullscreenToggel()
    },
    routerSkip(path) {
      this.$router.push(path)
    },
    open(menu) {
      this.$router
        .push({
          path: this.$router.$avueRouter.getPath({
            name: menu.label,
            src: menu.path,
          }),
          query: menu.query,
        })
        .catch(() => {})
    },
    setCollapse() {
      this.$store.commit('SET_COLLAPSE')
    },
    setScreen() {
      this.$store.commit('SET_FULLSCREN')
    },
    logout() {
      this.$confirm('是否退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'is-plain',
        confirmButtonClass: 'is-plain',
        type: 'warning',
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.replace(`${location.origin}/#/login/`)
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.avue-top {
  .user-icon {
    margin: 0px 8px 0 0;
    font-size: 24px;
    color: #fff;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #fff;
  }
  .collapse-icon {
    cursor: pointer;
    height: 48px;
    margin-right: 3px;
  }
  .top-bar__left {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .top-bar__right {
    /deep/.el-button {
      span {
        color: #fff !important;
      }
    }
  }
}
.drop-logout {
  font-weight: 700;
}
.el-popper {
  margin-top: -14px !important;
}
</style>
