<template>
  <div class="avue-logo">
    <transition name="fade">
      <img v-if="keyCollapse" class="icon-sidebar-fold" alt="" :src="appIcon" />
    </transition>
    <transition name="fade">
      <img
        v-if="!keyCollapse"
        :src="appFullPicture"
        class="icon-sidebar-unfold"
        alt=""
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Logo',
  data() {
    return {
      appIcon: '/img/sideIcon/inoah-fold.svg',
      appFullPicture: '/img/sideIcon/iNoah-white.svg',
    }
  },
  computed: {
    ...mapGetters(['website', 'keyCollapse']),
  },
  created() {
    const appIcon = localStorage.getItem('OO-paas-icon')
      ? JSON.parse(localStorage.getItem('OO-paas-icon')).content.appIcon
      : ''
    const appFullPicture = localStorage.getItem('OO-paas-icon')
      ? JSON.parse(localStorage.getItem('OO-paas-icon')).content.appFullPicture
      : ''
    this.appIcon = appIcon || this.appIcon
    this.appFullPicture = appFullPicture || this.appFullPicture
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter-active {
  transition: opacity 2.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.avue-logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 240px;
  height: 50px;
  line-height: 50px;
  background-color: #1f264d;
  font-size: 20px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: -2px 1px 4px 0 rgba(5, 10, 20, 0.6);
  color: rgba(255, 255, 255, 0.8);
  z-index: 1024;
  .icon-sidebar-fold {
    height: 35px;
    margin-top: 8px;
    margin-left: 18px;
    height: 30px;
  }
  img {
    height: 48px;
  }
  // .icon-sidebar-unfold {
  //   height: 35px;
  //   margin-top: 12px;
  //   margin-left: 25px;
  // }
  &_title {
    display: block;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
  }
  &_subtitle {
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
  }
  .avue-logo-header {
    position: absolute;
    top: 0;
    bottom: 8px;
    left: 20px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
  }
}
</style>
