import Vue from 'vue'
import Element from 'element-ui'
import animated from 'animate.css'
import '@/styles/theme/base.scss'
import '@/styles/theme/theme/index.scss'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/theme/element.scss'

// 全局修改默认配置
Element.Button.props.plain = { type: Boolean, default: true }
Element.Table.props.size = { type: String, default: 'medium' }
Element.Table.props.border = { type: Boolean, default: true }
Element.Radio.props.size = { type: String, default: 'mini' }
Element.Checkbox.props.size = { type: String, default: 'mini' }
Element.Link.props.underline = false
Element.Form.props.labelWidth = { type: String, default: '105px' }
Element.Pagination.props.background = { type: Boolean, default: true }
Element.Steps.props.finishStatus.default = 'success'
Element.Select.props.popperAppendToBody.default = false
Vue.use(Element, { size: 'small' }, animated)
