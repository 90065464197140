<template>
  <el-popover placement="bottom" width="200" trigger="click">
    <div
      v-for="(theme, index) of themes"
      :key="theme.value"
      :style="{
        'margin-bottom': index + 1 === themes.length ? '0' : '16px',
      }"
    >
      <el-radio v-model="curTheme" :label="theme.value">{{
        theme.label
      }}</el-radio>
    </div>
    <!-- <svg
      slot="reference"
      class="icon"
      style="margin: 8px 20px 0 0; width: 16px; height: 16px; cursor: pointer"
    >
      <use xlink:href="#icon-zhuti-04"></use>
    </svg> -->
    <i
      slot="reference"
      class="iconfont icon-a-xianxinghuanfu"
      style="margin: 3px 20px 0 0; width: 20px; height: 28px; cursor: pointer"
    ></i>
  </el-popover>
</template>

<script>
import { getCookieDomain } from '@/util/store.js'
import Cookie from 'js-cookie'
export default {
  data() {
    return {
      curTheme: 'theme-default',
      themes: [
        {
          label: '默认主题',
          value: 'theme-default',
        },
        {
          label: '深色主题',
          value: 'theme-dark',
        },
        {
          label: 'vip主题',
          value: 'theme-vip',
        },
        {
          label: '墨绿主题',
          value: 'theme-green',
        },
      ],
    }
  },
  watch: {
    curTheme: function (curTheme) {
      let curDate = new Date()
      curDate.setTime(curDate.getTime() + 365 * 24 * 60 * 60 * 1000)
      document.cookie = `theme=${curTheme};domain=${getCookieDomain()};path=/;expires=${curDate.toUTCString()}`
      document.body.className = curTheme
    },
  },

  mounted() {
    const theme = Cookie.get('theme')
    if (theme) {
      this.curTheme = theme
    }
  },
}
</script>

<style lang="scss" scoped>
.icon {
  fill: #fff;
}
</style>
