import { validatenull } from './validate'
// import request from '@/router/axios'
import * as CryptoJS from 'crypto-js'

// 表单序列化
export const serialize = data => {
  const list = []
  Object.keys(data).forEach(ele => {
    list.push(`${ele}=${data[ele]}`)
  })
  return list.join('&')
}
export const getObjType = obj => {
  var toString = Object.prototype.toString
  var map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object',
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}
/**
 * 对象深拷贝
 */
export const deepClone = data => {
  var type = getObjType(data)
  var obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    return data
  }
  if (type === 'array') {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (var key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}
/**
 * 判断路由是否相等
 */
export const diff = (obj1, obj2) => {
  delete obj1.close
  var o1 = obj1 instanceof Object
  var o2 = obj2 instanceof Object
  if (!o1 || !o2) {
    /*  判断不是对象  */
    return obj1 === obj2
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false
    // Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
  }

  for (var attr in obj1) {
    var t1 = obj1[attr] instanceof Object
    var t2 = obj2[attr] instanceof Object
    if (t1 && t2) {
      return diff(obj1[attr], obj2[attr])
    } else if (obj1[attr] !== obj2[attr]) {
      return false
    }
  }
  return true
}
/**
 * 设置灰度模式
 */
export const toggleGrayMode = status => {
  if (status) {
    document.body.className = document.body.className + ' grayMode'
  } else {
    document.body.className = document.body.className.replace(' grayMode', '')
  }
}
/**
 * 设置主题
 */
export const setTheme = name => {
  document.body.className = name
}

/**
 *加密处理
 */
export const encryption = params => {
  let { key } = params
  const { data, type, param } = params
  const result = JSON.parse(JSON.stringify(data))
  if (type === 'Base64') {
    param.forEach(ele => {
      result[ele] = btoa(result[ele])
    })
  } else {
    param.forEach(ele => {
      var data = result[ele]
      key = CryptoJS.enc.Latin1.parse(key)
      var iv = key
      // 加密
      var encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding,
      })
      result[ele] = encrypted.toString()
    })
  }
  return result
}

/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
  if (fullscreenEnable()) {
    exitFullScreen()
  } else {
    reqFullScreen()
  }
}
/**
 * esc监听全屏
 */
export const listenfullscreen = callback => {
  function listen() {
    callback()
  }

  document.addEventListener('fullscreenchange', () => {
    listen()
  })
  document.addEventListener('mozfullscreenchange', () => {
    listen()
  })
  document.addEventListener('webkitfullscreenchange', () => {
    listen()
  })
  document.addEventListener('msfullscreenchange', () => {
    listen()
  })
}
/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
  return (
    document.isFullScreen ||
    document.mozIsFullScreen ||
    document.webkitIsFullScreen
  )
}

/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.documentElement.requestFullScreen()
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.documentElement.webkitRequestFullScreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen()
  }
}
/**
 * 浏览器退出全屏
 */
export const exitFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.exitFullScreen()
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.webkitCancelFullScreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.mozCancelFullScreen()
  }
}
/**
 * 递归寻找子类的父类
 */

export const findParent = (menu, id) => {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].children.length !== 0) {
      for (let j = 0; j < menu[i].children.length; j++) {
        if (menu[i].children[j].id === id) {
          return menu[i]
        } else {
          if (menu[i].children[j].children.length !== 0) {
            return findParent(menu[i].children[j].children, id)
          }
        }
      }
    }
  }
}

/**
 * 动态插入css
 */

export const loadStyle = url => {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = url
  const head = document.getElementsByTagName('head')[0]
  head.appendChild(link)
}
/**
 * 判断路由是否相等
 */
export const isObjectValueEqual = (a, b) => {
  let result = true
  Object.keys(a).forEach(ele => {
    const type = typeof a[ele]
    if (
      (type === 'string' && a[ele] !== b[ele]) ||
      (type === 'object' && JSON.stringify(a[ele]) !== JSON.stringify(b[ele]))
    ) {
      result = false
    }
  })
  return result
}
/**
 * 根据字典的value显示label
 */
export const findByvalue = (dic, value) => {
  let result = ''
  if (validatenull(dic)) return value
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    let index = 0
    index = findArray(dic, value)
    if (index !== -1) {
      result = dic[index].label
    } else {
      result = value
    }
  } else if (value instanceof Array) {
    result = []
    let index = 0
    value.forEach(ele => {
      index = findArray(dic, ele)
      if (index !== -1) {
        result.push(dic[index].label)
      } else {
        result.push(value)
      }
    })
    result = result.toString()
  }
  return result
}
/**
 * 根据字典的value查找对应的index
 */
export const findArray = (dic, value) => {
  for (let i = 0; i < dic.length; i++) {
    if (dic[i].value === value) {
      return i
    }
  }
  return -1
}
/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = ''
  random = Math.ceil(
    window.crypto.getRandomValues(new Uint8Array(1)) * 100000000000,
  )
    .toString()
    .substr(0, len || 4)
  if (date) random = random + Date.now()
  return random
}

// 通用表单校验
export const validateTrim = (rule, value, callback) => {
  const reg = new RegExp('\\s+', 'ig')
  if (reg.test(value)) {
    callback(new Error('不能包含空格'))
  } else {
    callback()
  }
}
export const validatePhone = (rule, value, callback) => {
  if (!/^1[3456789]\d{9}$/.test(value)) {
    callback(new Error('手机格式不正确'))
  } else {
    callback()
  }
}
export const validateEmail = (rule, value, callback) => {
  const checkEmail =
    /^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$/
  const isemil = checkEmail.test(value)
  if (value === '' || !isemil) {
    callback(new Error('邮箱格式不正确'))
  } else {
    callback()
  }
}
export const validateDic = (rule, value, callback) => {
  if (!/^(?![a-zA-Z]+$)(?![_]+$)[A-Za-z_][A-Za-z_]{0,}$/.test(value)) {
    callback(new Error('输入只能为字母、下划线组合'))
  } else {
    callback()
  }
}
// 新密码校验不能包含空格和中文字符，最大为10个字符
export const validatePassword = (rule, value, callback) => {
  const regTest2 = /\s/
  const regTest =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、])[A-Za-z\d`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{8,20}$/
  console.log(regTest.test(value))

  if (regTest2.test(value)) {
    callback(new Error('不能包含空格'))
  } else if (!regTest.test(value)) {
    callback(new Error('不符合密码规则'))
  } else {
    callback()
  }
}

// 删除数据时，保持当前行高亮
export const addClass = (line, page, selector) => {
  selector = selector || '.el-table__row'
  let currentPageNum = 0 // 当前页的条数
  const { currentPage, pageSize, total } = page
  if (Math.ceil(total / pageSize) > currentPage) {
    currentPageNum = pageSize
  } else {
    currentPageNum = total % pageSize
  }
  document.querySelectorAll(selector)[line].classList.add('current-row')
  // eslint-disable-next-line prettier/prettier
  document
    .querySelectorAll(selector)
    [line + currentPageNum].classList.add('current-row')
  // eslint-disable-next-line prettier/prettier
  document
    .querySelectorAll(selector)
    [line + 2 * currentPageNum].classList.add('current-row')
}
export const removeClass = (line, page, selector) => {
  selector = selector || '.el-table__row'
  let currentPageNum = 0 // 当前页的条数
  const { currentPage, pageSize, total } = page
  if (Math.ceil(total / pageSize) > currentPage) {
    currentPageNum = pageSize
  } else {
    currentPageNum = total % pageSize
  }
  document.querySelectorAll(selector)[line].classList.remove('current-row')
  // eslint-disable-next-line prettier/prettier
  document
    .querySelectorAll(selector)
    [line + currentPageNum].classList.remove('current-row')
  // eslint-disable-next-line prettier/prettier
  document
    .querySelectorAll(selector)
    [line + 2 * currentPageNum].classList.remove('current-row')
}
export const validateUrl = (rule, value, callback) => {
  value = value.toLowerCase()
  const rules =
    '^((https|http|ftp|rtsp|mms|ldap)?://)' + // https、http、ftp、rtsp、mms
    "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" + // ftp的user@
    '(([0-9]{1,3}\\.){3}[0-9]{1,3}' + // IP形式的URL- 例如：199.194.52.184
    '|' + // 允许IP和DOMAIN（域名）
    "([0-9a-z_!~*'()-]+\\.)*" + // 域名- www.
    '([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\\.' + // 二级域名
    '[a-z]{2,6})' + // first level domain- .com or .museum
    '(:[0-9]{1,5})?' + // 端口号最大为65535,5位数
    '((/?)|' + // a slash isn't required if there is no file name
    "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$"
  const regTest = new RegExp(rules)
  if (!regTest.test(value)) {
    callback(new Error('请输入合法的地址'))
  } else {
    callback()
  }
}

// 获取url的参数
export const getUrlQuery = () => {
  const url = location.href // 获取url中"?"符后的字串
  // eslint-disable-next-line no-new-object
  const theRequest = new Object()
  if (url.indexOf('?') !== -1) {
    const str = url.split('?')[1]
    const strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
    }
  }
  return theRequest
}

// 获取指定url的hostname
export const getUrlHostname = (url = '') => {
  const matcht =
    /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i
  const result = matcht.exec(url)
  return result[2]
}
