export default {
  title: 'OO',
  logo: 'OO',
  key: 'OO', // 配置主键,目前用于存储
  indexTitle: '智能运维平台',
  whiteList: ['/login', '/404', '/401', '/lock'], // 配置无权限可以访问的页面
  whiteTagList: ['/login', '/404', '/401', '/lock'], // 配置不添加tags页面 （'/advanced-router/mutative-detail/*'——*为通配符）
  lockPage: '/lock',
  tokenTime: 6000,
  infoTitle: '智能运维平台',
  statusWhiteList: [428],
  // 配置应用中心不可关闭
  isFirstPage: false,
  fistPage: {
    label: '操作审计',
    value: '/console/admin/audit/index',
    params: {},
    query: {},
    group: [],
    close: false,
  },
  // 配置菜单的属性
  menu: {
    props: {
      label: 'label',
      path: 'path',
      icon: 'icon',
      children: 'children',
    },
  },
}
