var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-wrapper"},_vm._l((_vm.menu),function(item){return _c('div',{key:item[_vm.pathKey]},[(
        _vm.validatenull(item[_vm.childrenKey]) &&
        _vm.vaildRoles(item) &&
        (item.hasPermission === 1 ||
          (item.hasPermission === 0 && item.noPermitView === 'disable'))
      )?_c('el-menu-item',{key:item[_vm.labelKey],class:{ 'is-active': _vm.vaildActive(item) },attrs:{"disabled":item.hasPermission === 0 && item.hasPermission === 'disable',"index":item[_vm.pathKey]},on:{"click":function($event){return _vm.open(item)}}},[_c('i',{class:['iconfont', item[_vm.iconKey]]}),_c('span',{attrs:{"slot":"title","alt":item[_vm.pathKey]},slot:"title"},[_vm._v(_vm._s(item[_vm.labelKey]))])]):(
        !_vm.validatenull(item[_vm.childrenKey]) &&
        _vm.vaildRoles(item) &&
        (item.hasPermission === 1 ||
          (item.hasPermission === 0 && item.noPermitView === 'disable'))
      )?_c('el-submenu',{key:item[_vm.labelKey],class:{ 'is-active': _vm.vaildActive(item, true) },attrs:{"index":item[_vm.pathKey]}},[_c('template',{slot:"title"},[_c('i',{class:['iconfont', item[_vm.iconKey]]}),_c('span',{class:{ 'el-menu--display': _vm.collapse && _vm.first },attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item[_vm.labelKey]))])]),_vm._l((item[_vm.childrenKey]),function(child,cindex){return _c('div',{key:child[_vm.labelKey]},[(
            _vm.validatenull(child[_vm.childrenKey]) &&
            (child.hasPermission === 1 ||
              (child.hasPermission === 0 && child.noPermitView === 'disable'))
          )?_c('el-menu-item',{key:child[_vm.labelKey],class:{ 'is-active': _vm.vaildActive(child) },staticStyle:{"padding-left":"12px !important"},attrs:{"index":((child[_vm.pathKey]) + "," + cindex),"disabled":child.hasPermission === 0 && child.noPermitView === 'disable'},on:{"click":function($event){return _vm.open(child)}}},[_c('i',{staticClass:"iconfont icon-dian children__icon"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(child[_vm.labelKey]))])]):_c('sidebar-item',{key:cindex,attrs:{"menu":[child],"props":_vm.props,"screen":_vm.screen,"collapse":_vm.collapse}})],1)})],2):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }