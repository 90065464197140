<template>
  <div class="avue-contail" :class="{ 'avue--collapse': isCollapse }">
    <div class="avue-header">
      <!-- 顶部导航栏 -->
      <top />
    </div>
    <div class="avue-main">
      <!-- 顶部标签卡 -->
      <!-- <tags /> -->
      <div class="avue-left">
        <!--  左侧导航栏 -->
        <sidebar />
      </div>
      <!-- 主体视图层 -->
      <el-scrollbar
        style="height: 100%; width: calc(100% - 192px); display: inline-block"
        :class="{ 'collapse-min': isCollapse }"
      >
        <keep-alive>
          <router-view v-if="$route.meta.$keepAlive" class="avue-view" />
        </keep-alive>
        <router-view v-if="!$route.meta.$keepAlive" class="avue-view" />
      </el-scrollbar>
    </div>

    <div class="avue-shade" @click="showCollapse"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import top from './top/'
import sidebar from './sidebar/'
import admin from '@/util/admin'
import { validatenull } from '@/util/validate'
import { getStore } from '@/util/store.js'

export default {
  name: 'Index',
  components: {
    top,
    sidebar,
  },
  props: [],
  data() {
    return {
      // 刷新token锁
      refreshLock: false,
      // 刷新token的时间
      refreshTime: '',
    }
  },
  computed: mapGetters([
    'userInfo',
    'isLock',
    'isCollapse',
    'website',
    'expires_in',
  ]),
  created() {
    // 实时检测刷新token
    // this.refreshToken()
  },
  destroyed() {
    clearInterval(this.refreshTime)
  },
  mounted() {
    this.init()
  },
  methods: {
    showCollapse() {
      this.$store.commit('SET_COLLAPSE')
    },
    // 屏幕检测
    init() {
      this.$store.commit('SET_SCREEN', admin.getScreen())
      window.onresize = () => {
        setTimeout(() => {
          this.$store.commit('SET_SCREEN', admin.getScreen())
        }, 0)
      }
    },
    // 实时检测刷新token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        const token = getStore({
          name: 'access_token',
          debug: true,
          isCookie: true,
        })

        if (validatenull(token)) {
          return
        }

        if (this.expires_in <= 1000 && !this.refreshLock) {
          this.refreshLock = true
          this.$store.dispatch('RefreshToken').catch(() => {
            clearInterval(this.refreshTime)
          })
          this.refreshLock = false
        }
        this.$store.commit('SET_EXPIRES_IN', this.expires_in - 10)
      }, 10000)
    },
  },
}
</script>
<style lang="scss"></style>
