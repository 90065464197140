<template>
  <div class="avue-sidebar">
    <el-scrollbar style="height: 100%">
      <div
        v-if="validatenull(menu) && initLoading"
        v-loading="initLoading"
        element-loading-text="数 据 加 载 中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="#1d3e72"
        class="avue-sidebar--tip"
      ></div>
      <div v-if="validatenull(menu) && !initLoading" class="avue-sidebar--tip">
        没有发现菜单
      </div>
      <el-menu
        unique-opened
        :default-active="nowTagValue"
        :default-openeds="defaultOpeneds"
        mode="vertical"
        :show-timeout="200"
        :collapse="keyCollapse"
      >
        <sidebar-item
          :menu="menu"
          :screen="screen"
          first
          :props="website.menu.props"
          :collapse="keyCollapse"
          @getDefaultOpeneds="getDefaultOpeneds"
        ></sidebar-item>
      </el-menu>
      <div
        :class="isCollapse ? ' aside__truecollapse' : 'aside__collapse'"
        @mouseenter="isShow = true"
      >
        <svg
          class="icon"
          style="width: 20px; height: 28px; cursor: pointer"
          @click="showCollapse"
        >
          <use
            :xlink:href="isCollapse ? '#icon-zhedie2' : '#icon-zhedie1'"
          ></use>
        </svg>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import logo from '../logo'
import sidebarItem from './sidebarItem'
import Cookie from 'js-cookie'

export default {
  name: 'Sidebar',
  components: { sidebarItem, logo },
  data() {
    return {
      initLoading: true,
      defaultOpeneds: [],
    }
  },
  computed: {
    ...mapGetters([
      'website',
      'menu',
      'tag',
      'keyCollapse',
      'screen',
      'isCollapse',
    ]),
    nowTagValue: function () {
      return this.$router.$avueRouter.getValue(this.$route)
    },
  },
  created() {
    const userId = JSON.parse(Cookie.get('OO-refresh_userInfo')).content.userId
    this.$store
      .dispatch('GetMenu', { type: true, userId })
      .then(data => {
        this.initLoading = false
        if (data.length === 0) return
        this.$router.$avueRouter.formatRoutes(data, true)
      })
      .catch(() => {
        this.initLoading = false
      })
  },
  mounted() {},
  methods: {
    showCollapse() {
      this.$store.commit('SET_COLLAPSE')
    },
    getDefaultOpeneds(val) {
      this.defaultOpeneds = val
    },
  },
}
</script>
<style lang="scss" scoped>
$active-color: #165dff;
.avue-sidebar {
  ::v-deep .el-menu {
    height: 100%;
    background-color: #fff;
    border-right: none;
    color: #86909c;
    &:not(.el-menu--collapse) {
      width: 192px;
      overflow: hidden;
    }
    .el-menu-item,
    .el-submenu__title {
      background-color: #fff;
      color: #86909c;
      height: 48px;
      display: flex;
      align-items: center;
      i {
        color: #86909c;
        margin-right: 8px;
      }
      span {
        color: #86909c;
      }
      &.is-active {
        background: transparent;
        span {
          color: $active-color;
        }
        i {
          color: $active-color;
        }
        .children__icon {
          color: #165dff;
        }
      }
      &:hover {
        background: transparent;
        i {
          color: $active-color;
        }
        span {
          color: $active-color;
        }
      }
      .children__icon {
        font-size: 30px;
        margin: 0 4px 0 1px;
      }
    }
    .el-submenu {
      .el-icon-arrow-right {
        display: none;
      }
    }

    .is-opened {
      background: transparent;
    }

    .is-collapse {
      cursor: pointer;
      &:hover {
        background: transparent;
      }
    }
  }
}
</style>
