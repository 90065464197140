import { getStore, setStore } from '@/util/store'
import { isURL, validatenull } from '@/util/validate'
import {
  getUserInfo,
  loginByUsername,
  logout,
  refreshToken,
  commonAudit,
} from '@/api/login'
import { deepClone, encryption } from '@/util/util'
import webiste from '@/const/website'
import { getMenu } from '@/api/admin/menu'
import moment from 'moment'

function addPath(ele, first) {
  const menu = webiste.menu
  const propsConfig = menu.props
  const propsDefault = {
    label: propsConfig.label || 'name',
    path: propsConfig.path || 'path',
    icon: propsConfig.icon || 'icon',
    children: propsConfig.children || 'children',
  }
  const icon = ele[propsDefault.icon]
  ele[propsDefault.icon] = validatenull(icon) ? menu.iconDefault : icon
  const isChild =
    ele[propsDefault.children] && ele[propsDefault.children].length !== 0
  if (!isChild) ele[propsDefault.children] = []
  if (!isChild && first && !isURL(ele[propsDefault.path])) {
    ele[propsDefault.path] = ele[propsDefault.path] + '/index'
  } else {
    ele[propsDefault.children].forEach(child => {
      addPath(child)
    })
  }
}
function CommonAudit(data) {
  return new Promise((resolve, reject) => {
    console.log(data)
    commonAudit({
      clientIp: '',
      operationStartTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      operationEndTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      operationUser: data.username || data.userInfo.username,
      operationType: data.operationType,
      operationDescription: data.operationType,
      appName: 'paas-auth',
    })
      .then(() => {
        resolve()
      })
      .catch(error => {
        console.log(error)
        resolve(error)
      })
  })
}
const user = {
  state: {
    userInfo: {},
    permissions: {},
    roles: [],
    menu:
      getStore({
        name: 'menu',
      }) || [],
    menuAll: [],
    expires_in:
      getStore({
        name: 'expires_in',
        debug: false,
        isCookie: true,
      }) || '',
    access_token:
      getStore({
        name: 'access_token',
        debug: false,
        isCookie: true,
      }) || '',
    refresh_token:
      getStore({
        name: 'refresh_token',
        debug: false,
        isCookie: true,
      }) || '',
  },
  actions: {
    // 根据用户名登录
    LoginByUsername({ commit }, userInfo) {
      const user = encryption({
        data: userInfo,
        key: 'paas for onlyone',
        param: ['password'],
      })
      return new Promise((resolve, reject) => {
        loginByUsername(
          user.username,
          user.password,
          user.code,
          user.randomStr,
          user.r_url,
        )
          .then(async response => {
            const data = response.data
            commit('SET_ACCESS_TOKEN', data.access_token)
            commit('SET_REFRESH_TOKEN', data.refresh_token)
            commit('SET_EXPIRES_IN', data.expires_in)
            commit('CLEAR_LOCK')
            // 登录成功 记录登录信息
            await CommonAudit({ ...response.data, operationType: '登录' })
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then(res => {
            const data = res.data.data || {}
            let userInfo = {
              adminFlag: data.sysUser.adminFlag,
              displayName: encodeURIComponent(data.sysUser.displayName),
              userId: data.sysUser.userId,
              username: data.sysUser.username,
            }
            commit('SET_USER_INFO', userInfo)
            commit('SET_ROLES', data.roles || [])
            commit('SET_PERMISSIONS', data.permissions || [])
            resolve(data)
          })
          .catch(() => {
            reject()
          })
      })
    },
    // 刷新token
    RefreshToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        refreshToken(state.refresh_token)
          .then(response => {
            const data = response.data
            commit('SET_ACCESS_TOKEN', data.access_token)
            commit('SET_REFRESH_TOKEN', data.refresh_token)
            commit('SET_EXPIRES_IN', data.expires_in)
            commit('CLEAR_LOCK')
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(async response => {
            await CommonAudit({ ...state, operationType: '失效' })
            commit('SET_MENU', [])
            commit('SET_PERMISSIONS', [])
            commit('SET_USER_INFO', {})
            commit('SET_ACCESS_TOKEN', '')
            commit('SET_REFRESH_TOKEN', '')
            commit('SET_EXPIRES_IN', '')
            commit('SET_ROLES', [])
            commit('DEL_ALL_TAG')
            commit('CLEAR_LOCK')
            // 登录成功 记录登录信息
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 注销session
    FedLogOut({ commit, state }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async resolve => {
        await CommonAudit({ ...state, operationType: '失效' })
        commit('SET_MENU', [])
        commit('SET_PERMISSIONS', [])
        commit('SET_USER_INFO', {})
        commit('SET_ACCESS_TOKEN', '')
        commit('SET_REFRESH_TOKEN', '')
        commit('SET_ROLES', [])
        commit('DEL_ALL_TAG')
        commit('CLEAR_LOCK')
        resolve()
      })
    },
    // 获取系统菜单
    GetMenu({ commit }, obj) {
      const appId = localStorage.getItem('OO-appId')
        ? JSON.parse(localStorage.getItem('OO-appId')).content.appId
        : ''
      return new Promise(resolve => {
        getMenu(obj.userId, appId).then(res => {
          const { menuItemViews } = res.data.data
          const menu = deepClone(menuItemViews)
          menu.forEach(ele => {
            addPath(ele)
          })
          const type = obj.type
          commit('SET_MENU', { type, menu })
          resolve(menu)
        })
      })
    },
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, access_token) => {
      state.access_token = access_token
      setStore({
        name: 'access_token',
        content: state.access_token,
        type: '',
        cors: true,
      })
    },
    SET_EXPIRES_IN: (state, expires_in) => {
      state.expires_in = expires_in
      setStore({
        name: 'expires_in',
        content: state.expires_in,
        type: '',
        cors: true,
      })
    },
    SET_REFRESH_TOKEN: (state, rfToken) => {
      state.refresh_token = rfToken
      setStore({
        name: 'refresh_token',
        content: state.refresh_token,
        type: '',
        cors: true,
      })
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
      setStore({
        name: 'refresh_userInfo',
        content: state.userInfo,
        type: '',
        cors: true,
      })
    },
    SET_MENU: (state, params = {}) => {
      const { menu, type } = params
      if (type !== false) state.menu = menu
      setStore({
        name: 'menu',
        content: menu,
        type: '',
      })
    },
    SET_MENU_ALL: (state, menuAll) => {
      state.menuAll = menuAll
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      const list = {}
      for (let i = 0; i < permissions.length; i++) {
        list[permissions[i]] = true
      }
      state.permissions = list
    },
  },
}
export default user
